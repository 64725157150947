// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var Caml_string = require("bs-platform/lib/js/caml_string.js");

function isFullId(str) {
  return Caml_string.get(str, 0) ===
  /* "@" */
  64;
}

function toFullId(baseId, resourceId, localId) {
  if (baseId === "") {
    return undefined;
  } else if (resourceId === "") {
    return "@" + (baseId + ("/" + localId));
  } else {
    return "@" + (baseId + ("/" + (resourceId + ("/" + localId))));
  }
}

exports.isFullId = isFullId;
exports.toFullId = toFullId;
/* No side effect */