// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var JsonKeys =
/* module */
[
/* facts */
"facts",
/* things */
"things",
/* thingIdField */
"id"];
var Value =
/* module */
[
/* dataTypeField */
"dataValue",
/* dataField */
"data",
/* jsonType */
"json",
/* stringType */
"string",
/* thingIdType */
"thingId"];
var Fields =
/* module */
[
/* id */
"id",
/* subjectId */
"subjectId",
/* propertyId */
"propertyId",
/* value */
"value",
/* baseId */
"baseId",
/* resourceId */
"resourceId",
/* idIsPublic */
"idIsPublic"];
var FactJson =
/* module */
[
/* Value */
Value,
/* Fields */
Fields];
exports.JsonKeys = JsonKeys;
exports.FactJson = FactJson;
/* No side effect */