// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

var Config$Reason = require("../Config.bs.js");

function to_json(v) {
  var match = v[
  /* valueType */
  0];

  switch (match.tag | 0) {
    case 0:
      return Json_encode.object_(
      /* :: */
      [
      /* tuple */
      [Config$Reason.FactJson[
      /* Value */
      0][
      /* dataTypeField */
      0], Config$Reason.FactJson[
      /* Value */
      0][
      /* stringType */
      3]],
      /* :: */
      [
      /* tuple */
      [Config$Reason.FactJson[
      /* Value */
      0][
      /* dataField */
      1], match[0]],
      /* [] */
      0]]);

    case 1:
      return Json_encode.object_(
      /* :: */
      [
      /* tuple */
      [Config$Reason.FactJson[
      /* Value */
      0][
      /* dataTypeField */
      0], Config$Reason.FactJson[
      /* Value */
      0][
      /* thingIdType */
      4]],
      /* :: */
      [
      /* tuple */
      [Config$Reason.FactJson[
      /* Value */
      0][
      /* dataField */
      1], match[0]],
      /* [] */
      0]]);

    case 2:
      return Json_encode.object_(
      /* :: */
      [
      /* tuple */
      [Config$Reason.FactJson[
      /* Value */
      0][
      /* dataTypeField */
      0], Config$Reason.FactJson[
      /* Value */
      0][
      /* jsonType */
      2]],
      /* :: */
      [
      /* tuple */
      [Config$Reason.FactJson[
      /* Value */
      0][
      /* dataField */
      1], match[0]],
      /* [] */
      0]]);
  }
}

function from_json(v) {
  var _type = Json_decode.field(Config$Reason.FactJson[
  /* Value */
  0][
  /* dataTypeField */
  0], Json_decode.string, v);

  switch (_type) {
    case "string":
      return (
        /* String */
        Block.__(0, [Json_decode.field(Config$Reason.FactJson[
        /* Value */
        0][
        /* dataField */
        1], Json_decode.string, v)])
      );

    case "thingId":
      return (
        /* ThingId */
        Block.__(1, [Json_decode.field(Config$Reason.FactJson[
        /* Value */
        0][
        /* dataField */
        1], Json_decode.string, v)])
      );

    default:
      return (
        /* ThingId */
        Block.__(1, [Json_decode.field(Config$Reason.FactJson[
        /* Value */
        0][
        /* dataField */
        1], Json_decode.string, v)])
      );
  }
}

exports.to_json = to_json;
exports.from_json = from_json;
/* No side effect */