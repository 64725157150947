/* Untyped file generated by genType. */
import * as InterfaceBS from './Interface.bs';
export var Graph_fromJson = InterfaceBS.Graph[0];
export var Graph_things = InterfaceBS.Graph[1];
export var Graph_facts = InterfaceBS.Graph[2];
export var Graph_factList = InterfaceBS.Graph[3];
export var Graph_directoryArray = InterfaceBS.Graph[4];
export var Graph_childDirectories = InterfaceBS.Graph[5];
export var Graph_rootDirectories = InterfaceBS.Graph[6];
export var Graph_findThing = InterfaceBS.Graph[7];
export var Graph_findThingFromFact = InterfaceBS.Graph[8];
export var Graph_to_json = InterfaceBS.Graph[9];
export var Graph_EdgeTypes_property = InterfaceBS.Graph[10][0];
export var Graph_EdgeTypes_subject = InterfaceBS.Graph[10][1];
export var Thing_to_json = InterfaceBS.Thing[0];
export var Directory_parent = InterfaceBS.Directory[0];
export var Fact_to_json = InterfaceBS.Fact[0];
export var Fact_value = InterfaceBS.Fact[1];
export var Filter_query = InterfaceBS.Filter[0];
export var Query_from_json = InterfaceBS.Query[0];
export var Value_to_json = InterfaceBS.Value[0];
export var list_to_array = InterfaceBS.list_to_array;