// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");

var $$Array = require("bs-platform/lib/js/array.js");

var Curry = require("bs-platform/lib/js/curry.js");

var Js_dict = require("bs-platform/lib/js/js_dict.js");

var Graph_T$Reason = require("./Graph_T.bs.js");

var RList$Rationale = require("rationale/src/RList.js");

var Function$Rationale = require("rationale/src/Function.js");

function bases(t) {
  return t[
  /* bases */
  2];
}

function makeDirs(t) {
  var allThingIdStrings = List.map(function (e) {
    return e[
    /* thingId */
    0][
    /* thingIdString */
    0];
  }, $$Array.to_list(Js_dict.values(t[
  /* things */
  0])));
  var partial_arg = Graph_T$Reason.Directory[
  /* allSubdirectories */
  7];

  var partial_arg$1 = function partial_arg$1(param) {
    return List.map(partial_arg, param);
  };

  var partial_arg$2 = List.filter(function (e) {
    return !Graph_T$Reason.Directory[
    /* isFactDirectory */
    6](e);
  });
  var partial_arg$3 = Graph_T$Reason.Directory[
  /* parent */
  9];

  var partial_arg$4 = function partial_arg$4(param) {
    return List.map(partial_arg$3, param);
  };

  var partial_arg$5 = Function$Rationale.Infix[
  /* ||> */
  1];

  var partial_arg$6 = function partial_arg$6(param) {
    return partial_arg$5(partial_arg$4, partial_arg$2, param);
  };

  var partial_arg$7 = Function$Rationale.Infix[
  /* ||> */
  1];

  var partial_arg$8 = function partial_arg$8(param) {
    return partial_arg$7(partial_arg$6, partial_arg$1, param);
  };

  var partial_arg$9 = Function$Rationale.Infix[
  /* ||> */
  1];

  var partial_arg$10 = function partial_arg$10(param) {
    return partial_arg$9(partial_arg$8, List.concat, param);
  };

  var partial_arg$11 = Function$Rationale.Infix[
  /* ||> */
  1];

  var possibleUniqueIds = function possibleUniqueIds(param) {
    return partial_arg$11(partial_arg$10, RList$Rationale.uniq, param);
  };

  var directories = Curry._1(possibleUniqueIds, allThingIdStrings);

  return (
    /* record */
    [
    /* things */
    t[
    /* things */
    0],
    /* facts */
    t[
    /* facts */
    1],
    /* bases */
    t[
    /* bases */
    2],
    /* directories */
    directories]
  );
}

exports.bases = bases;
exports.makeDirs = makeDirs;
/* Graph_T-Reason Not a pure module */