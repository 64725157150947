// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");

var $$Array = require("bs-platform/lib/js/array.js");

var Curry = require("bs-platform/lib/js/curry.js");

var Caml_obj = require("bs-platform/lib/js/caml_obj.js");

var Function$Rationale = require("rationale/src/Function.js");

function log2(v1, v2) {
  console.log(v1, v2);
  return v2;
}

var isEqual = Caml_obj.caml_equal;
var isNotEqual = Caml_obj.caml_notequal;

function accumulator(_history, _accum, f, _l) {
  while (true) {
    var l = _l;
    var accum = _accum;
    var history = _history;

    if (l) {
      var newel = Curry._2(f, accum, l[0]);

      _l = l[1];
      _accum = newel;
      _history = List.append(history,
      /* :: */
      [newel,
      /* [] */
      0]);
      continue;
    } else {
      return history;
    }
  }

  ;
}

function asList(fn) {
  var partial_arg = Function$Rationale.Infix[
  /* ||> */
  1];

  var partial_arg$1 = function partial_arg$1(param) {
    return partial_arg($$Array.to_list, fn, param);
  };

  var partial_arg$2 = Function$Rationale.Infix[
  /* ||> */
  1];
  return function (param) {
    return partial_arg$2(partial_arg$1, $$Array.of_list, param);
  };
}

function asArray(fn) {
  var partial_arg = Function$Rationale.Infix[
  /* ||> */
  1];

  var partial_arg$1 = function partial_arg$1(param) {
    return partial_arg($$Array.of_list, fn, param);
  };

  var partial_arg$2 = Function$Rationale.Infix[
  /* ||> */
  1];
  return function (param) {
    return partial_arg$2(partial_arg$1, $$Array.to_list, param);
  };
}

exports.log2 = log2;
exports.isEqual = isEqual;
exports.isNotEqual = isNotEqual;
exports.accumulator = accumulator;
exports.asList = asList;
exports.asArray = asArray;
/* No side effect */