// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");

var Graph_T$Reason = require("../Graph/Graph_T.bs.js");

var Graph_Dirs$Reason = require("../Graph/Graph_Dirs.bs.js");

var Graph_Fact$Reason = require("../Graph/Graph_Fact.bs.js");

var Function$Rationale = require("rationale/src/Function.js");

var Graph_Graph$Reason = require("../Graph/Graph_Graph.bs.js");

var Graph_Value$Reason = require("../Graph/Graph_Value.bs.js");

var Graph_Fact_Query$Reason = require("../Graph/Graph_Fact_Query.bs.js");

var Graph_Fact_Filters$Reason = require("../Graph/Graph_Fact_Filters.bs.js");

var SimpleFactList_ToGraph$Reason = require("../AlternativeGraphFormats/SimpleFactList_ToGraph.bs.js");

var CompressedImporter__FromJson$Reason = require("../Importers/CompressedImporter/CompressedImporter__FromJson.bs.js");

var CompressedImporter__ToSimpleFactList$Reason = require("../Importers/CompressedImporter/CompressedImporter__ToSimpleFactList.bs.js");

var partial_arg = Function$Rationale.Infix[
/* ||> */
1];

function partial_arg$1(param) {
  return partial_arg(CompressedImporter__FromJson$Reason.run, CompressedImporter__ToSimpleFactList$Reason.run, param);
}

var partial_arg$2 = Function$Rationale.Infix[
/* ||> */
1];

function partial_arg$3(param) {
  return partial_arg$2(partial_arg$1, SimpleFactList_ToGraph$Reason.run, param);
}

var partial_arg$4 = Function$Rationale.Infix[
/* ||> */
1];

function fromJson(param) {
  return partial_arg$4(partial_arg$3, Graph_Dirs$Reason.makeDirs, param);
}

var things = Graph_T$Reason.F[
/* thingArray */
1];
var facts = Graph_T$Reason.F[
/* factArray */
4];
var factList = Graph_T$Reason.F[
/* factList */
5];
var partial_arg$5 = Graph_T$Reason.F[
/* directories */
6];
var partial_arg$6 = Function$Rationale.Infix[
/* ||> */
1];

function directoryArray(param) {
  return partial_arg$6(partial_arg$5, $$Array.of_list, param);
}

function childDirectories(g, s) {
  return $$Array.of_list(Graph_T$Reason.F[
  /* childDirectories */
  8](g, s));
}

var partial_arg$7 = Graph_T$Reason.F[
/* rootDirectories */
7];
var partial_arg$8 = Function$Rationale.Infix[
/* ||> */
1];

function rootDirectories(param) {
  return partial_arg$8(partial_arg$7, $$Array.of_list, param);
}

var findThing = Graph_T$Reason.F[
/* findThing */
2];
var EdgeTypes =
/* module */
[
/* property : PROPERTY */
1,
/* subject : SUBJECT */
0];
var Graph =
/* module */
[
/* fromJson */
fromJson,
/* things */
things,
/* facts */
facts,
/* factList */
factList,
/* directoryArray */
directoryArray,
/* childDirectories */
childDirectories,
/* rootDirectories */
rootDirectories,
/* findThing */
findThing,
/* findThingFromFact */
Graph_Graph$Reason.findThingFromFact,
/* to_json */
Graph_Graph$Reason.to_json,
/* EdgeTypes */
EdgeTypes];
var to_json = Graph_T$Reason.Thing[
/* to_json */
2];
var Thing =
/* module */
[
/* to_json */
to_json];
var parent = Graph_T$Reason.Directory[
/* parent */
9];
var Directory =
/* module */
[
/* parent */
parent];
var Fact =
/* module */
[
/* to_json */
Graph_Fact$Reason.to_json,
/* value */
Graph_Fact$Reason.value];
var Filter =
/* module */
[
/* query */
Graph_Fact_Filters$Reason.query];
var from_json = Graph_Fact_Query$Reason.Query[
/* from_json */
3];
var Query =
/* module */
[
/* from_json */
from_json];
var Value =
/* module */
[
/* to_json */
Graph_Value$Reason.to_json];
var list_to_array = $$Array.of_list;
exports.Graph = Graph;
exports.Thing = Thing;
exports.Directory = Directory;
exports.Fact = Fact;
exports.Filter = Filter;
exports.Query = Query;
exports.Value = Value;
exports.list_to_array = list_to_array;
/* Graph_T-Reason Not a pure module */