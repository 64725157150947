// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");

var $$Array = require("bs-platform/lib/js/array.js");

function factToJs(param) {
  return {
    id: param[
    /* id */
    0],
    subjectId: param[
    /* subjectId */
    1],
    propertyId: param[
    /* propertyId */
    2],
    value: param[
    /* value */
    3]
  };
}

function factFromJs(param) {
  return (
    /* record */
    [
    /* id */
    param.id,
    /* subjectId */
    param.subjectId,
    /* propertyId */
    param.propertyId,
    /* value */
    param.value]
  );
}

function showFacts(g) {
  return $$Array.map(factToJs, $$Array.of_list(g));
}

var combine = List.concat;
exports.factToJs = factToJs;
exports.factFromJs = factFromJs;
exports.showFacts = showFacts;
exports.combine = combine;
/* No side effect */