// Generated by BUCKLESCRIPT VERSION 4.0.14, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");

var $$Array = require("bs-platform/lib/js/array.js");

var Block = require("bs-platform/lib/js/block.js");

var Js_dict = require("bs-platform/lib/js/js_dict.js");

var Caml_obj = require("bs-platform/lib/js/caml_obj.js");

var Belt_List = require("bs-platform/lib/js/belt_List.js");

var Graph_T$Reason = require("../Graph/Graph_T.bs.js");

var RList$Rationale = require("rationale/src/RList.js");

var Option$Rationale = require("rationale/src/Option.js");

var Function$Rationale = require("rationale/src/Function.js");

var IdConverter$Reason = require("./IdConverter.bs.js");

var SecureRandomString = require("@ncthbrt/re-secure-random-string/src/SecureRandomString.bs.js");

var Compiler_AST$Reason = require("./Compiler_AST.bs.js");

function makeThingId(id) {
  return (
    /* record */
    [
    /* rawId */
    id,
    /* tag */
    undefined,
    /* thingIdType */
    undefined,
    /* updatedId */
    undefined]
  );
}

function thingIdKey(e) {
  return (
    /* tuple */
    [e[
    /* rawId */
    0], e[
    /* tag */
    1]]
  );
}

function allPrimaryIds(g) {
  var factIds = List.flatten(List.map(function (r) {
    return (
      /* :: */
      [r[
      /* thingId */
      0],
      /* :: */
      [r[
      /* subjectId */
      1],
      /* :: */
      [r[
      /* propertyId */
      2],
      /* [] */
      0]]]
    );
  }, g[
  /* facts */
  0]));
  var aliasIds = $$Array.to_list(Js_dict.values(g[
  /* aliases */
  3]));
  return List.append(aliasIds, factIds);
}

function findUniqueIds(g) {
  return RList$Rationale.uniqBy(thingIdKey, allPrimaryIds(g));
}

function tagFacts(g) {
  List.iter(function (fact) {
    fact[
    /* thingId */
    0][
    /* tag */
    1] = SecureRandomString.genSync(12, true,
    /* () */
    0);
    return (
      /* () */
      0
    );
  }, g[
  /* facts */
  0]);
  return g;
}

function useUniqueThingIds(g) {
  var uniqueIds = RList$Rationale.uniqBy(thingIdKey, allPrimaryIds(g));

  var findId = function findId(thingId) {
    return List.find(function (e) {
      return Caml_obj.caml_equal(thingIdKey(e), thingIdKey(thingId));
    }, uniqueIds);
  };

  var facts = List.map(function (r) {
    var match = r[
    /* value */
    4];
    var tmp;
    tmp = match.tag ?
    /* Id */
    Block.__(1, [findId(match[0])]) : r[
    /* value */
    4];
    return (
      /* record */
      [
      /* thingId */
      findId(r[
      /* thingId */
      0]),
      /* subjectId */
      findId(r[
      /* subjectId */
      1]),
      /* propertyId */
      findId(r[
      /* propertyId */
      2]),
      /* isInversed */
      r[
      /* isInversed */
      3],
      /* value */
      tmp]
    );
  }, g[
  /* facts */
  0]);
  var aliases = Js_dict.fromArray($$Array.map(function (param) {
    return (
      /* tuple */
      [param[0], findId(param[1])]
    );
  }, Js_dict.entries(g[
  /* aliases */
  3])));
  return (
    /* record */
    [
    /* facts */
    facts,
    /* baseId */
    g[
    /* baseId */
    1],
    /* resourceId */
    g[
    /* resourceId */
    2],
    /* aliases */
    aliases]
  );
}

function handleThingTypes(g) {
  var propertyOrSubjectType = function propertyOrSubjectType(id) {
    var match = id[
    /* thingIdType */
    2];

    if (match !== undefined && !match) {
      return (
        /* FACT */
        0
      );
    } else {
      return (
        /* NONFACT */
        1
      );
    }
  };

  List.iter(function (r) {
    var id = r[
    /* thingId */
    0];
    id[
    /* thingIdType */
    2] =
    /* FACT */
    0;
    return (
      /* () */
      0
    );
  }, g[
  /* facts */
  0]);
  List.iter(function (r) {
    var propertyId = r[
    /* propertyId */
    2];
    propertyId[
    /* thingIdType */
    2] = propertyOrSubjectType(propertyId);
    var subjectId = r[
    /* subjectId */
    1];
    subjectId[
    /* thingIdType */
    2] = propertyOrSubjectType(subjectId);
    return (
      /* () */
      0
    );
  }, g[
  /* facts */
  0]);
  return g;
}

function findId(uniqueIds, thingId) {
  return List.find(function (e) {
    return Caml_obj.caml_equal(thingIdKey(e), thingIdKey(thingId));
  }, uniqueIds);
}

function _convertValue($$package, uniqueIds, fact) {
  var match = fact[
  /* value */
  4];

  if (match.tag) {
    return (
      /* Id */
      Block.__(1, [match[0]])
    );
  } else {
    var str = match[0];
    var __x = $$package[
    /* aliases */
    3];
    var alias = Js_dict.get(__x, str);

    if (alias !== undefined) {
      return (
        /* Id */
        Block.__(1, [alias])
      );
    } else {
      var e = Belt_List.getBy(uniqueIds, function (e) {
        return Caml_obj.caml_equal(thingIdKey(e),
        /* tuple */
        [str, undefined]);
      });

      if (e !== undefined) {
        return (
          /* Id */
          Block.__(1, [e])
        );
      } else {
        return (
          /* String */
          Block.__(0, [str])
        );
      }
    }
  }
}

function linkValues(p) {
  var uniqueIds = RList$Rationale.uniqBy(thingIdKey, allPrimaryIds(p));
  List.iter(function (fact) {
    fact[
    /* value */
    4] = _convertValue(p, uniqueIds, fact);
    return (
      /* () */
      0
    );
  }, p[
  /* facts */
  0]);
  return p;
}

function convertIdd($$package, thingId) {
  var __x = $$package[
  /* aliases */
  3];
  var alias = Js_dict.get(__x, Option$Rationale.$$default("", thingId[
  /* rawId */
  0]));
  var match = thingId[
  /* rawId */
  0];

  if (match !== undefined) {
    var r = match;
    var exit = 0;

    if (alias !== undefined) {
      var match$1 = alias[
      /* rawId */
      0];

      if (match$1 !== undefined) {
        return match$1;
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }

    if (exit === 1) {
      if (IdConverter$Reason.isFullId(r)) {
        return r;
      } else {
        return IdConverter$Reason.toFullId($$package[
        /* baseId */
        1], $$package[
        /* resourceId */
        2], r);
      }
    }
  }
}

function generateFactId(thingId, subjectId, $$package) {
  var subject = Option$Rationale.toExn("Subject ThingID expected to have updatedID by this point of pipeline", subjectId[
  /* updatedId */
  3]);
  var isInSameBase = Graph_T$Reason.Directory[
  /* root */
  5](subject) === $$package[
  /* baseId */
  1];
  var tagId = Option$Rationale.toExn("Expected fact to have fact ID at this point.", thingId[
  /* tag */
  1]);

  if (isInSameBase) {
    return subject + ("/_f/" + tagId);
  } else {
    return Option$Rationale.toExn("Expect Full Base Id Id", IdConverter$Reason.toFullId($$package[
    /* baseId */
    1], $$package[
    /* resourceId */
    2], "_f/" + tagId));
  }
}

function handleUpdatedIds(p) {
  var uniqueIds = RList$Rationale.uniqBy(thingIdKey, allPrimaryIds(p));
  List.iter(function (id) {
    var match = id[
    /* thingIdType */
    2];

    if (match !== undefined && match) {
      id[
      /* updatedId */
      3] = convertIdd(p, id);
      return (
        /* () */
        0
      );
    } else {
      return (
        /* () */
        0
      );
    }
  }, uniqueIds);
  List.iter(function (fact) {
    fact[
    /* thingId */
    0][
    /* updatedId */
    3] = generateFactId(fact[
    /* thingId */
    0], fact[
    /* subjectId */
    1], p);
    return (
      /* () */
      0
    );
  }, p[
  /* facts */
  0]);
  return p;
}

function showFacts(g) {
  return $$Array.map(Compiler_AST$Reason.factToJs, $$Array.of_list(g[
  /* facts */
  0]));
}

function showIds(g) {
  return $$Array.map(Compiler_AST$Reason.thingIdToJs, $$Array.of_list(RList$Rationale.uniqBy(thingIdKey, allPrimaryIds(g))));
}

function inverseFact(fact) {
  var match = fact[
  /* value */
  4];

  if (match.tag) {
    return (
      /* record */
      [
      /* thingId */
      fact[
      /* thingId */
      0],
      /* subjectId */
      match[0],
      /* propertyId */
      fact[
      /* propertyId */
      2],
      /* isInversed */
      false,
      /* value : Id */
      Block.__(1, [fact[
      /* subjectId */
      1]])]
    );
  } else {
    return (
      /* record */
      [
      /* thingId */
      fact[
      /* thingId */
      0],
      /* subjectId : record */
      [
      /* rawId */
      match[0],
      /* tag */
      undefined,
      /* thingIdType */
      undefined,
      /* updatedId */
      undefined],
      /* propertyId */
      fact[
      /* propertyId */
      2],
      /* isInversed */
      false,
      /* value : Id */
      Block.__(1, [fact[
      /* subjectId */
      1]])]
    );
  }
}

function handleInverseFacts($$package) {
  return (
    /* record */
    [
    /* facts */
    List.map(function (f) {
      var match = f[
      /* isInversed */
      3];

      if (match) {
        return inverseFact(f);
      } else {
        return f;
      }
    }, $$package[
    /* facts */
    0]),
    /* baseId */
    $$package[
    /* baseId */
    1],
    /* resourceId */
    $$package[
    /* resourceId */
    2],
    /* aliases */
    $$package[
    /* aliases */
    3]]
  );
}

var partial_arg = Function$Rationale.Infix[
/* ||> */
1];

function partial_arg$1(param) {
  return partial_arg(handleInverseFacts, tagFacts, param);
}

var partial_arg$2 = Function$Rationale.Infix[
/* ||> */
1];

function partial_arg$3(param) {
  return partial_arg$2(partial_arg$1, useUniqueThingIds, param);
}

var partial_arg$4 = Function$Rationale.Infix[
/* ||> */
1];

function partial_arg$5(param) {
  return partial_arg$4(partial_arg$3, handleThingTypes, param);
}

var partial_arg$6 = Function$Rationale.Infix[
/* ||> */
1];

function partial_arg$7(param) {
  return partial_arg$6(partial_arg$5, linkValues, param);
}

var partial_arg$8 = Function$Rationale.Infix[
/* ||> */
1];

function run(param) {
  return partial_arg$8(partial_arg$7, handleUpdatedIds, param);
}

function convertId(f) {
  return (
    /* record */
    [
    /* id */
    Option$Rationale.toExn("", f[
    /* updatedId */
    3]),
    /* isPublic */
    false]
  );
}

function toSimple(g) {
  return List.map(function (f) {
    var match = f[
    /* value */
    4];
    var tmp;

    if (match.tag) {
      var id = match[0];
      tmp =
      /* ThingId */
      Block.__(1, [Option$Rationale.toExn("Error: thingId does not have #updatedId when needed: " + Option$Rationale.$$default("", id[
      /* rawId */
      0]), id[
      /* updatedId */
      3])]);
    } else {
      tmp =
      /* String */
      Block.__(0, [match[0]]);
    }

    return (
      /* record */
      [
      /* id */
      convertId(f[
      /* thingId */
      0]),
      /* subjectId */
      convertId(f[
      /* subjectId */
      1]),
      /* propertyId */
      convertId(f[
      /* propertyId */
      2]),
      /* value : record */
      [
      /* valueType */
      tmp]]
    );
  }, g[
  /* facts */
  0]);
}

exports.makeThingId = makeThingId;
exports.thingIdKey = thingIdKey;
exports.allPrimaryIds = allPrimaryIds;
exports.findUniqueIds = findUniqueIds;
exports.tagFacts = tagFacts;
exports.useUniqueThingIds = useUniqueThingIds;
exports.handleThingTypes = handleThingTypes;
exports.findId = findId;
exports._convertValue = _convertValue;
exports.linkValues = linkValues;
exports.convertIdd = convertIdd;
exports.generateFactId = generateFactId;
exports.handleUpdatedIds = handleUpdatedIds;
exports.showFacts = showFacts;
exports.showIds = showIds;
exports.inverseFact = inverseFact;
exports.handleInverseFacts = handleInverseFacts;
exports.run = run;
exports.convertId = convertId;
exports.toSimple = toSimple;
/* Graph_T-Reason Not a pure module */